<template>
  <table class="table table-bordered">
    <thead class="thead-dark">
      <tr>
        <th class="text-center">#</th>
        <th>Name</th>
        <th class="text-center">Trainer</th>
        <th class="text-center">Wochentag</th>
        <th class="text-center">Von</th>
        <th class="text-center">Bis</th>
        <th class="text-center">Kursraum</th>
        <th class="text-center">Bearbeiten</th>
        <th class="text-center">Löschen</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td colspan="9">
          <router-link :to="{name: 'course-create'}">+ Kurs erstellen</router-link>
        </td>
      </tr>
      <tr>
        <td colspan="9">
          <input
            type="search"
            class="form-control form-control-sm"
            v-model.trim="search"
            placeholder="Kurs suchen..."
          />
        </td>
      </tr>
      <tr v-for="(course, index) in filteredCourses" :key="course.id">
        <th class="text-center">{{ index + 1 }}</th>
        <td>{{ course.name }}</td>
        <td class="text-center">{{course.trainer}}</td>
        <td class="text-center">{{course.weekday | weekday}}</td>
        <td class="text-center">{{course.starts}}</td>
        <td class="text-center">{{course.ends}}</td>
        <td class="text-center">{{course.courseRoom | courseRoom(courseRooms)}}</td>
        <td class="text-center">
          <router-link :to="{name: 'course-details', params: {id: course.id}}">
            <i class="fa fa-edit text-dark"></i>
          </router-link>
        </td>
        <td class="text-center" @click="remove(course.id)">
          <i class="fa fa-trash cursor-pointer"></i>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { firestore } from '../firebase';

export default {
  data() {
    return {
      courses: [],
      courseRooms: [],
      loading: false,
      search: ''
    };
  },
  async created() {
    this.loading = true;

    const coursesSnapshot = await firestore
      .collection('courses')
      .where('studio', '==', this.$route.params.studioId)
      .orderBy('weekday')
      .orderBy('starts')
      .get();

    const courseRoomsSnapshot = await firestore
      .collection('studios')
      .doc(this.$route.params.studioId)
      .collection('courseRooms')
      .get();

    this.courses = coursesSnapshot.docs.map(snapshot => {
      return {
        id: snapshot.id,
        ...snapshot.data()
      };
    });

    this.courseRooms = courseRoomsSnapshot.docs.map(snapshot => {
      return {
        id: snapshot.id,
        ...snapshot.data()
      };
    });

    this.loading = false;
  },
  methods: {
    async remove(id) {
      if (confirm('Bist du sicher, dass du diesen Kurs löschen möchtest?')) {
        await firestore
          .collection('courses')
          .doc(id)
          .delete();

        this.courses = this.courses.filter(course => course.id !== id);
      }
    }
  },
  computed: {
    filteredCourses() {
      return this.courses.filter(course => {
        return course.name.toLowerCase().includes(this.search.toLowerCase());
      });
    }
  }
};
</script>
